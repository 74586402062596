<template>
  <div class="main-menu-wrapper">

    <div v-for="(page, idx) in  activeSurvey.pages" :key="idx" @click="onPageClicked(page.extname)"
         :class="['menuItem', (activePage===idx) ? 'menuActive' : '']">
      <div>
        <v-icon :color="iconColor(activePage===idx)">{{ page.icon }}</v-icon>
      </div>
      <div class="menuText">
        {{ page.title }}
      </div>
    </div>
    <div class="menuItem menuItemButtom">
      <v-dialog
          v-model="supportDialog"
          width="500"
          class="supportDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="supportMenuDialogWrapper" v-on="on" v-bind="attrs">
            <div>
              <img src="../assets/ag-logo-support.png" />
<!--              <v-icon color="#757575">mdi-lifebuoy</v-icon>-->
            </div>
            <div>
              {{ 'SUPPORT_MENU_TITLE' | i18n }}
            </div>
          </div>
        </template>
        <v-card>
          <v-card-title>{{ 'SUPPORT_DIALOG_TITLE' | i18n }}</v-card-title>
          <v-card-subtitle>{{ 'SUPPORT_DIALOG_SUBTITLE' | i18n }}</v-card-subtitle>

          <v-list
              two-line
              class="pa-2"
          >
              <v-list-item v-for="link in supportLinks" :key="link.title">
<!--              <v-list-item v-for="link in support.links" :key="link.title">-->
                <v-list-item-content class="itemContent" @click="onSupportLinkClicked(link)">
                  <v-list-item-title class="supportTitle">{{ link.title }}</v-list-item-title>
                  {{ link.text }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="onSupportLinkClicked(link)">
                    <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
          </v-list>

          <v-divider class="mb-5"></v-divider>

          <v-card-text>
            <span class="supportTitle">{{ 'SUPPORT_DIALOG_PHONE_NUMBER_TITLE' | i18n }}</span><br/>
            {{ 'SUPPORT_DIALOG_PHONE_NUMBER' | i18n }}
          </v-card-text>

          <v-card-text>
            <span class="supportTitle">{{ 'SUPPORT_DIALOG_OPENING_HOURS_TITLE' | i18n }}</span><br/>
            {{ 'SUPPORT_DIALOG_OPENING_HOURS' | i18n }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="supportDialog = false"
            >
              {{ 'SUPPORT_DIALOG_CLOSE' | i18n }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import PageNavigator from "../pagenavigator"
import _ from 'lodash'
import Clientconfig from "@/clientconfig";

export default {
  name: 'MainSidebar',

  components: {},

  props: {
    miniVariant: Boolean
  },

  data() {
    return {
      activePage: null,
      supportDialog: false,
      support: {
        links: [
          {
            title: this.$t('SUPPORT_DIALOG_FAQ_TITLE'),
            text: this.$t('SUPPORT_DIALOG_FAQ_TEXT'),
            link: this.$t('SUPPORT_DIALOG_FAQ_URL')
          },
          {
            title: this.$t('SUPPORT_DIALOG_EMAIL_TITLE'),
            text: this.$t('SUPPORT_DIALOG_EMAIL_TEXT'),
            link: this.$t('SUPPORT_DIALOG_EMAIL_URL')
          },
          {
            title: this.$t('SUPPORT_DIALOG_ZENDESK_TITLE'),
            text: this.$t('SUPPORT_DIALOG_ZENDESK_TEXT'),
            link: "https://aganalytics5886.zendesk.com/auth/v2/login/continue_with_sso_tracking?auth_origin=4418101799441%2Cfalse%2Ctrue&brand_id=4418101799441&locale=1&remote_auth_id=30637471019793&return_to=https%3A%2F%2Faganalytics5886.zendesk.com%2F&role=end_user&theme=hc"
          }
        ],
      }
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['isAlertFilterDefined', 'getFilteredDrillPath', 'getDefaultFilteredDrillPath', 'getAlertFilteredDrillPath', 'getPageById', 'getZendeskURL']),

    supportLinks() {
      const result = []

      result.push({
        title: this.$t('SUPPORT_DIALOG_FAQ_TITLE'),
        text: this.$t('SUPPORT_DIALOG_FAQ_TEXT'),
        link: this.getZendeskURL ? this.getZendeskURL : this.$t('SUPPORT_DIALOG_FAQ_URL')
      })

      result.push({
        title: this.$t('SUPPORT_DIALOG_EMAIL_TITLE'),
        text: this.$t('SUPPORT_DIALOG_EMAIL_TEXT'),
        link: this.$t('SUPPORT_DIALOG_EMAIL_URL')
      })

      return result
    },


    menuItemPadding() {
      return 0
    }

  },

  watch: {
    $route: {
      handler() {
        const name = (this.$route.name === 'page') ? this.$route.params.pageId : this.$route.name

        const pageIdx = _.findIndex(this.activeSurvey.pages, {extname: name})
        if (pageIdx !== -1) {
          this.activePage = pageIdx
        }
      },
      immediate: true
    }
  },

  methods: {



    onPageClicked(pageId) {
      const destPage = this.getPageById(pageId)

      if(destPage.selectionInterval) {
        this.$store.commit('setFilter', {filterName: 'ftime', filterValue: [destPage.selectionInterval]})
      }

      if (pageId === 'rankings') {
        PageNavigator.navigateToRankingsPage(this, this.activeSurvey.extName, this.calculateFilteredPath())
      } else if (pageId === 'alerts') {
        PageNavigator.navigateToAlertsPage(this, this.activeSurvey.extName, this.calculateAlertsFilteredPath())
      } else if (pageId === 'samples') {
        PageNavigator.navigateToSamplesPage(this, this.activeSurvey.extName, this.calculateFilteredPath())
      } else {
        PageNavigator.navigateToPage(this, pageId, this.activeSurvey.extName, this.calculateFilteredPath())
      }

    },

    calculateFilteredPath() {
      if (this.isAlertFilterDefined && (this.$route.name === 'alert' || this.$route.name === 'alerts')) {
        return this.getDefaultFilteredDrillPath
      } else {

        return this.getFilteredDrillPath
      }
    },

    calculateAlertsFilteredPath() {
      return this.isAlertFilterDefined ? this.getAlertFilteredDrillPath : this.getFilteredDrillPath
    },

    iconColor(isActive) {
      return isActive ? '#0000c8' : '#757575'
    },

    onSupportLinkClicked(link) {
      window.open(link.link, "_blank")
    }

  }

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.itemContent {
  cursor: pointer;
}

div.main-menu-wrapper {
  height: 100%;
  overflow: hidden; /* To avoid scrollbar */
  position: relative
}

div.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-left: #ffffff 4px solid;
}

div.menuActive {
  color: #0000c8 !important;
  background-color: #e1e1f9;
  /*text-decoration-color: white !important;*/
  border-left: #0d47a1 4px solid !important;
}

div.menuText {
  margin-top: 2px;
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);

}


div.menuItemButtom {
  position: absolute;
  margin-left: -50px;
  left: 50%;
  width: 100px;
  bottom: 10px;
  /*font-size: 13px;*/
  font-size: calc((13/16)*1rem);

  background-color: white;
}

div.supportMenuDialogWrapper {
  align-items: center;
}

div.supportMenuDialogWrapper div {
  text-align: center;
  width: 100px;
}

.itemContent {
  /*font-size: 14px;*/
  font-size: calc((14/16)*1rem);
}

.supportTitle {
  /*font-size: 16px;*/
  font-size: calc((16/16)*1rem);

  font-weight: bold;
  color: black;
}

</style>
