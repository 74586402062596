
let clientConfiguration = null


function setConfig(clientConfigDTO) {
    clientConfiguration = clientConfigDTO
}


function isKeyCloakMode() {
    return clientConfiguration.keyCloakMode //true //window.location.origin.includes("localhost:8081")
}

function isKeyCloakExternIdPMode() {
    return clientConfiguration.keyCloakExternIdPMode //true //window.location.origin.includes("localhost:8081")
}

function keyCloakServer() {
    return clientConfiguration.keyCloakURL // "https://pnaccess.aganalytics.se"
}

function keyCloakRealm() {
    return clientConfiguration.keyCloakRealm
//    return "postnordad"
}

function keyCloakClientId() {
    return clientConfiguration.keyCloakClient
}

function isLoaded() {
    return clientConfiguration!==null
}

function keyCloakAccountURL() {
    return clientConfiguration.keyCloakURL + '/realms/' +  clientConfiguration.keyCloakRealm + '/account'
}

function systemLogoURL() {
    return clientConfiguration.systemLogoURL
}

function zendeskURL() {
    return clientConfiguration.zendeskURL
}


export default {
    setConfig,
    isKeyCloakMode,
    isKeyCloakExternIdPMode,
    keyCloakServer,
    keyCloakRealm,
    keyCloakClientId,
    keyCloakAccountURL,
    systemLogoURL,
    zendeskURL,
    isLoaded
}
